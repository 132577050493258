import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  ALL_SERVICES,
  REQUESTED_CATEGORIES_URL_PARAM_NAME,
} from '../../consts';
import { filterServicesBySelectedTab } from '../../utils/services/services';
import {
  EnrichedService,
  FilterOption,
  FilterServicesByOptions,
} from '../../types/types';
import { WidgetViewModel } from '../../viewModel/viewModel';
import settingsParams from '../../components/BookOnline/settingsParams';
import { BookingsQueryParams } from '@wix/bookings-catalog-calendar-viewer-utils';
import { BookingsAPI } from '../../api/BookingsApi';
import { getAdditionalServicesData } from '../getAdditionalServicesData/getAdditionalServicesData';
import { getEnrichedServicesAndAvailability } from '../../utils/getEnrichedServicesAndAvailability/getEnrichedServicesAndAvailability';
import { getLoadingButtonsText } from '../../viewModel/bodyViewModel/bodyViewModel';
import { getPaginationSEOMetadata } from '../../utils/pagination/pagination';

export type OnFilterOptionSelectedAction = (
  selectedFilterOption: FilterOption,
) => void;

export const createOnFilterOptionSelectedAction = ({
  widgetViewModel,
  services: previousServices,
  setProps,
  flowAPI,
  bookingsApi,
  activeFeatures,
  isPricingPlanInstalled,
  serviceListContext,
}: {
  services: EnrichedService[];
  widgetViewModel: WidgetViewModel;
  setProps: Function;
  flowAPI: ControllerFlowAPI;
  bookingsApi: BookingsAPI;
  activeFeatures: any;
  isPricingPlanInstalled: boolean;
  serviceListContext: any;
}): OnFilterOptionSelectedAction => {
  return async (selectedFilterOption: FilterOption) => {
    const {
      settings,
      controllerConfig: { wixCodeApi },
      experiments,
    } = flowAPI;
    const filterServicesBy = settings.get(settingsParams.filterServicesBy);
    const fetchTabsInServiceListEnabled = experiments.enabled(
      'specs.bookings.fetchTabsInServiceList',
    );

    const isMigrateServiceListToServiceV2Enabled = experiments.enabled(
      'specs.bookings.migrateServiceListToServiceV2',
    );

    if (
      widgetViewModel.filterOptions.find((option) => option.isSelected)?.id ===
      selectedFilterOption.id
    ) {
      return;
    }

    if (
      fetchTabsInServiceListEnabled &&
      selectedFilterOption.id === ALL_SERVICES
    ) {
      wixCodeApi.location.queryParams.remove([
        REQUESTED_CATEGORIES_URL_PARAM_NAME,
        BookingsQueryParams.LOCATION,
        BookingsQueryParams.PAGE,
      ]);
    } else if (
      fetchTabsInServiceListEnabled &&
      filterServicesBy === FilterServicesByOptions.CATEGORIES
    ) {
      wixCodeApi.location.queryParams.add({
        [REQUESTED_CATEGORIES_URL_PARAM_NAME]: selectedFilterOption.id,
        [BookingsQueryParams.PAGE]: '',
      });
    } else if (
      fetchTabsInServiceListEnabled &&
      filterServicesBy === FilterServicesByOptions.LOCATIONS
    ) {
      wixCodeApi.location.queryParams.add({
        [BookingsQueryParams.LOCATION]: selectedFilterOption.id,
        [BookingsQueryParams.PAGE]: '',
      });
    }

    const newFilterOptions = widgetViewModel.filterOptions.map(
      (filterOption: FilterOption) => ({
        ...filterOption,
        isSelected: filterOption.id === selectedFilterOption.id,
      }),
    );

    if (
      fetchTabsInServiceListEnabled &&
      isMigrateServiceListToServiceV2Enabled
    ) {
      try {
        const { services, coursesAvailability, pagingMetadata } =
          await getEnrichedServicesAndAvailability({
            flowAPI,
            activeFeatures,
            isPricingPlanInstalled,
            isAnywhereFlow: serviceListContext.isAnywhereFlow,
            bookingsApi,
            selectedFilterOptionId: selectedFilterOption.id,
          });
        widgetViewModel.filterOptions = newFilterOptions;
        widgetViewModel.services = services;
        widgetViewModel.coursesAvailability = coursesAvailability;
        widgetViewModel.servicesPagingMetadata = pagingMetadata;

        const { loadPreviousButtonText, loadMoreButtonText } =
          getLoadingButtonsText({
            flowAPI,
            servicesPagingMetadata: widgetViewModel.servicesPagingMetadata,
          });

        const { nextUrl, prevUrl } = getPaginationSEOMetadata(
          flowAPI.controllerConfig.wixCodeApi,
          widgetViewModel.servicesPagingMetadata,
        );

        setProps({
          widgetViewModel: {
            ...widgetViewModel,
            bodyViewModel: {
              ...widgetViewModel.bodyViewModel,
              focusCardIndex: -1,
              loadPreviousButtonText,
              loadPreviousSeoHref: prevUrl,
              loadMoreButtonText,
              loadMoreSeoHref: nextUrl,
            },
          },
        });
      } catch (error) {
        console.error(error);
        widgetViewModel.errorMessage = flowAPI.translations.t(
          'error-state.change-tab.text',
        );
        setProps({
          widgetViewModel: { ...widgetViewModel },
        });
      }
    } else {
      const servicesToDisplay = filterServicesBySelectedTab({
        filterOptions: newFilterOptions,
        services: previousServices,
        settings,
      });

      const { coursesAvailability, services: populatedServices } =
        await getAdditionalServicesData({
          bookingsApi,
          services: servicesToDisplay,
          isServiceListEventsClassDaysEnable: flowAPI.experiments.enabled(
            'specs.bookings.ServiceListEventsClassDays',
          ),
        });

      widgetViewModel.filterOptions = newFilterOptions;
      widgetViewModel.services = populatedServices;
      widgetViewModel.coursesAvailability = coursesAvailability;

      setProps({
        widgetViewModel: { ...widgetViewModel },
      });
    }
  };
};
